<template>
  <div
    class="content-wrapper"
    :class="{
      '--edit': edit,
    }"
    :style="cssProps">
    <draggable
      v-if="edit"
      v-model="localContent.rows"
      group="rows"
      class="content"
      :style="{
        borderRadius: localContent.borderRadius,
        gridGap: localContent.gridGap,
        maxWidth: localContent.maxWidth,
        margin: localContent.maxWidth ? '0 auto' : '',
      }"
      @end="verticalDragEnd">
      <div
        v-for="(contentRow, rowIndex) in localContent.rows"
        :key="`content-row${rowIndex}`"
        class="content-row-wrapper">
        <draggable
          :list="contentRow.cells"
          group="cells"
          class="content-row"
          :style="{
            padding: contentRow.padding,
            borderRadius: contentRow.borderRadius || rowBorderRadius[rowIndex],
            backgroundColor: contentRow.backgroundColor,
            display: contentRow.display,
            gridTemplateColumns: contentRow.gridTemplateColumns,
            gridGap: contentRow.gridGap,
          }">
          <div
            v-for="(cell, cellIndex) in contentRow.cells"
            :key="`content-cell${cell.id || cellIndex}`"
            class="content-cell"
            :style="{
              padding: cell.padding,
              margin: cell.type != 'button' ? cell.margin : '',
              borderRadius: cell.borderRadius,
              backgroundColor: cell.type != 'button' ? cell.backgroundColor : '',
            }"
            @dblclick="$emit('editCell', { row: rowIndex, cell: cellIndex })">
            <ContentText
              v-if="cell.type === 'text'"
              :text="cell.text">
            </ContentText>
            <ContentTable
              v-else-if="cell.type === 'table'"
              :table="cell.table">
            </ContentTable>
            <button
              v-else-if="cell.type == 'button'"
              class="btn btn-activate"
              role="button"
              aria-roledescription="custom button"
              :style="{
                margin: cell.margin,
                borderRadius: cell.borderRadius,
                backgroundColor: cell.backgroundColor,
              }">{{ cell.text }}
            </button>
            <ContentImage
              v-else-if="cell.type === 'image'"
              :src="cell.media?.url || ''"
              :alt="cell.alt"
              :borderRadius="cell.borderRadius">
            </ContentImage>
            <ContentVideo
              v-else-if="cell.type === 'video'"
              :src="cell.media?.url || ''"
              :alt="cell.alt">
            </ContentVideo>
            <ContentIframe
              v-else-if="cell.type === 'iframe'"
              :src="cell.url"
              :alt="cell.alt">
            </ContentIframe>
            <!--
            show pencil at hover?
            show trashcan at hover?
            -->
          </div>
        </draggable>
        <div
          v-if="edit"
          class="content-edit">
          <iconComponent
            symbol="pencil"
            width="15px"
            @click="$emit('editRow', rowIndex)"></iconComponent>
        </div>
      </div>
    </draggable>
    <table
      v-else
      cellpadding="0"
      cellspacing="0"
      class="content"
      :style="{
        borderRadius: localContent.borderRadius,
        gridGap: localContent.gridGap,
        maxWidth: localContent.maxWidth,
        margin: localContent.maxWidth ? '0 auto' : '',
      }">
      <tbody>
        <tr
          v-for="(contentRow, rowIndex) in localContent.rows"
          :key="`content-row${rowIndex}`"
          class="content-row-wrapper">
          <td
            class="content-row"
            :style="{
              margin: rowMarginGap[rowIndex],
              padding: contentRow.padding,
              borderRadius: contentRow.borderRadius || rowBorderRadius[rowIndex],
              backgroundColor: contentRow.backgroundColor,
            }">
            <table
              cellpadding="0"
              cellspacing="0">
              <tbody
                :style="{
                  width: '100%',
                  display: contentRow.display,
                  gridTemplateColumns: contentRow.gridTemplateColumns,
                  gridGap: contentRow.gridGap,
                }">
                <tr
                  v-for="(cell, cellIndex) in contentRow.cells"
                  :key="`content-cell${cell.id || cellIndex}`">
                  <td
                    class="content-cell"
                    :style="{
                      padding: cell.padding,
                      margin: cell.type != 'button' ? cell.margin : '',
                      borderRadius: cell.borderRadius,
                      backgroundColor: cell.type != 'button' ? cell.backgroundColor : '',
                    }">
                    <!--
                      attempt at drag n drop fr om contentcreator
                      @drop="$emit('drop', { row: rowIndex, cell: cellIndex })"
                      @dragover.prevent-->
                    <ContentText
                      v-if="cell.type === 'text'"
                      :text="cell.text">
                    </ContentText>
                    <ContentTable
                      v-else-if="cell.type === 'table'"
                      :table="cell.table">
                    </ContentTable>
                    <button
                      v-else-if="cell.type == 'button'"
                      class="btn btn-activate"
                      role="button"
                      aria-roledescription="custom button"
                      :style="{
                        margin: cell.margin,
                        borderRadius: cell.borderRadius,
                        backgroundColor: cell.backgroundColor,
                      }">{{ cell.text }}
                    </button>
                    <ContentImage
                      v-else-if="cell.type === 'image'"
                      :src="cell.media?.url || ''"
                      :alt="cell.alt"
                      :borderRadius="cell.borderRadius">
                    </ContentImage>
                    <ContentVideo
                      v-else-if="cell.type === 'video'"
                      :src="cell.media?.url || ''"
                      :alt="cell.alt">
                    </ContentVideo>
                    <ContentIframe
                      v-else-if="cell.type === 'iframe'"
                      :src="cell.url"
                      :alt="cell.alt">
                    </ContentIframe>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="!localContent.rows">
      <p>No content available.</p>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import ContentText from './ContentText.vue';
import ContentTable from './ContentTable.vue';
import ContentImage from './ContentImage.vue';
import ContentVideo from './ContentVideo.vue';
import ContentIframe from './ContentIframe.vue';

export default {
  name: 'Content',
  components: {
    draggable,
    ContentText,
    ContentTable,
    ContentImage,
    ContentVideo,
    ContentIframe
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    edit: {
      type: Boolean,
      default: false
    },
    dpr: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      localContent: this.value,
      dragOptions: {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      },
    };
  },
  computed: {
    cssProps() {
      return {
        '--dpr': this.dpr,
        backgroundColor: this.localContent.backgroundColor,
        padding: this.localContent.margin,
      }
    },
    rowBorderRadius() {
      return this.localContent.rows.map((_, rowIndex) => {
        if (!this.localContent.gridGap || this.localContent.gridGap != '0px' || this.localContent.rows.length == 1) {
          return this.localContent.borderRadius;
        } else if (rowIndex === 0) {
          return `${this.localContent.borderRadius} ${this.localContent.borderRadius} 0 0`;
        } else if (rowIndex === this.localContent.rows.length - 1) {
          return `0 0 ${this.localContent.borderRadius} ${this.localContent.borderRadius}`;
        } else {
          return '';
        }
      });
    },
    rowMarginGap() {
      return this.localContent.rows.map((_, rowIndex) => {
        if (rowIndex != 0 && this.localContent.gridGap && this.localContent.gridGap != '0px') {
          return `${this.localContent.gridGap} 0 0 0`;
        }
      });
    },
    /*rowPadding() {
      return this.localContent.rows.map((_, rowIndex) => {
        if (rowIndex === 0) {
          return `${this.localContent.padding} ${this.localContent.padding} 10px ${this.localContent.padding}`;
        } else if (rowIndex === this.localContent.rows.length - 1) {
          return `10px ${this.localContent.padding} ${this.localContent.padding} ${this.localContent.padding}`;
        } else {
          return `10px ${this.localContent.padding}`;
        }
      });
    }*/
  },
  watch: {
    value: {
      handler(newValue) {
        this.localContent = newValue;
      },
      deep: true
    },
    localContent(newValue) {
      this.$emit('input', newValue);
    }
  },
  methods: {
    emitRow(value) {
      console.log('Emitting row:', value);
      this.$emit("input", value);
    },
    emitCell(value) {
      console.log('Emitting cell:', value);
      this.$emit("input", value);
    },
    verticalDragEnd() {
      this.$emit('verticalDragEnd');
    },
  },
}
</script>

<style scoped>
@import './Content.css';
</style>