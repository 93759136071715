<template>
  <div
    v-if="loadedPage"
    class="admin-entity-content--container">

    <EntityEditor
      v-if="loadedPage && communication._id"
      :title="communication.name"
      entity-type="Communication"
      :entity="communication"
      :admin="true"/>

    <div v-if="communication?._id">
      <Content
        v-if="communication?.content?._id"
        class="mobile"
        :dpr="4"
        :value="communication.content"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';
import Content from '../stories/components/Content/Content.vue';
import communicationApi from '../api/communication.api';

export default {
  name: 'AdminCommunication',
  components: {
    EntityEditor,
    Content,
  },
  props: ['communicationId'],
  mixins: [BeforeEnterMixin],
  mounted() {
    this.loadPage();
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters([
      "communication",
    ]),
    ...mapGetters('pvCompanies', {
      pvCommunication: "communication",
    }),
    parentGoodiebags() {
      if (this.communication && this.communication.communications && this.communication.communications.length) {
        let parentGoodiebags = [];
        for (let i = 0; i < this.communication.communications.length; i++) {
          const communication = this.communication.communications[i];
          if (!communication.parentGoodiebag) {
            parentGoodiebags.push(communication);
          }
        }
        return parentGoodiebags;
      }
      return [];
    },
  },
  watch: {
    communicationId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPage();
      }
    }
  },
  data() {
    return {
      loadedPage: false,
      search: '',
      showGoodiebagDialog: false,
      attachGoodiebag: [],
      previewGoodiebag: {},
    };
  },
  methods: {
    async loadPage() {
      this.loadedPage = false;
      await this.$store.dispatch('getAdminCommunication', { entityId: this.communicationId });
      this.setPageTitle('Admin', 'Communication');
      this.setAttachments();
      if (this.communication.pv_communication_id) {
        try {
          await this.$store.dispatch('pvCompanies/getAdminPVCommunication', { entityId: this.communication.pv_communication_id });
        } catch(error) {
          console.log('Could not load PV communication', error);
        }
      }
      this.loadedPage = true;
    },
    setAttachments() {
      this.attachGoodiebag = [
        {
          key: 'communication',
          value: {
            text: this.communication.name,
            value: this.communication._id,
          },
        },
        {
          key: 'communication',
          value: {
            text: '',
            value: '',
          },
        }
      ];
    },
    getGoodiebag(entityId) {
      communicationApi.getAdminGoodiebag({ entityId }).then((data) => {
        this.previewGoodiebag = data.communication;
        this.showPreviewGoodiebagModal = true;
      });
    },
    findAdminPVCommunication(query) {
      let exclude;
      if (query == '') {
        exclude = 'individual';
      }
      this.$store.dispatch('pvActivations/findAdminPVCommunication', { query, communicationId: this.communicationId, exclude }).then(() => {
        this.loadedPage = true;
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminCommunication";
</style>
